import amana from "./amana";
import gfc from "./gfc";
import centroid from "./centroid";
import { ILanguageCode } from "@/Lib/const";
import { RootTabParamList } from "types";
import es from "@/Lib/t/es.json";
import { IconName, themes } from "@amana/ui";
import { ReactElement } from "react";

const getConfig = (): IConfig => {
  if (typeof window === "undefined") return amana;

  const hostname = window?.location?.hostname;
  if (!hostname) return amana;
  if (hostname.includes("gfc")) return gfc;
  if (hostname.includes("centroid")) return centroid;

  return amana;
};

export default getConfig();

export interface IConfig {
  app_name: string;
  logo: (props?: { width?: number }) => ReactElement;
  horizontal_logo: (props?: { width?: number }) => ReactElement;
  languages: ILanguageCode[];
  default_language: string;
  theme: Partial<typeof themes.dark>;
  features: {
    isLeverageEnabled: boolean;
    isNotificationsEnabled: boolean;
    isSignupEnabled: boolean;
    isLanguageEnabled: boolean;
    isMTLoginEnabled: boolean;
    isSocialLoginEnabled: boolean;
    isDepositEnabled: boolean;
    isInternalTransferEnabled: boolean;
    isStatmentEnabled: boolean;
    isSupportEnabled: boolean;
    isSettingsEnabled: boolean;
    isNewsEnabled: boolean;
    isEventsEnabled: boolean;
    isContentCardsEnabled: boolean;
    isDailyUpdatesEnabled: boolean;
    isRecurringDepositEnabled: boolean;
    isTermsAndConditionsEnabled: boolean;
    isAppTourEnabled: boolean;
    isUAEPassEnabled: boolean;
    isWithdrawEnabled: boolean;
    customSignInSpinner?: () => ReactElement;
    isTradingViewChartUsed?: boolean;
    isStaticIcons: boolean;
  };
  navigation: Record<
    keyof RootTabParamList,
    {
      isShown: boolean;
      isMain: boolean;
      label?: (t: typeof es) => string;
      icon?: {
        name?: IconName;
        size?: number;
      };
    }
  >;
}
