import { getApps, initializeApp } from "firebase/app";

export const firebaseConfig = {
  apiKey: "AIzaSyAOCrOM5eoQ7-fUTeWw0SOwBmyXdAYsT-s",
  authDomain: "amanareact.firebaseapp.com",
  databaseURL:
    "https://amanareact-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "amanareact",
  storageBucket: "amanareact.appspot.com",
  messagingSenderId: "182007695339",
  appId: "1:182007695339:web:c34c58ee7c33caa25d7bae",
  measurementId: "G-7CG2GZSMZF",
};

export const firebaseApp =
  // create a new app only if it doesn't already exists
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
