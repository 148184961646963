import { useMobxValue } from "@/Features/PublicApp/Hooks";
import { settingsStore } from "@/Lib/stores/SettingsStore";
import { useColors as amUseColors, themes as Colors } from "@amana/ui";

export type Colors = keyof ReturnType<typeof amUseColors>;

export function useAppTheme() {
  const choosenTheme = useMobxValue(settingsStore, "theme");

  const toggle = (overide?: typeof choosenTheme) =>
    settingsStore.setTheme(
      overide || choosenTheme === "dark" ? "light" : "dark"
    );

  return [choosenTheme, Colors[choosenTheme], toggle] as const;
}
