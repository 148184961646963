import { symbolStore } from "../SymbolStore";
import { alerts } from "../../client";
import { navigate } from "@/helpers/navigation";
import { toastError, toastSuccess } from "@/components/ToastUI";

export interface AlertData {
  id: string;
  symbol: string;
  price: string;
  server: string;
  user_id: number;
  login: string;
  notification_methods: {
    sms: boolean;
    push: boolean;
    email: boolean;
  };
  created_at: string;
  updated_at: string;
}

export class Alert {
  data: AlertData;
  constructor(data: AlertData) {
    this.data = data;
  }

  get Symbol() {
    return symbolStore.getSymbolSync(this.data.symbol);
  }

  save(sms: boolean, push: boolean, email: boolean, price: string) {
    this.data.notification_methods.sms = !!sms;
    this.data.notification_methods.push = !!push;
    this.data.notification_methods.email = !!email;
    this.data.price = String(price);

    return alerts
      .update(this.data.id, {
        price: this.data.price,
        notification_methods: this.data.notification_methods,
        server: "live",
      })
      .then(({ data }: any) => {
        toastSuccess(data?.message || "Alert Updated");
      })
      .catch(() => toastError("could not update alert"));
  }

  confirm(data: AlertData) {
    navigate("ModifyAlert", { id: this.data.id, data: data || this.data });
  }
}
