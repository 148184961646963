import { Platform, StatusBar } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
// export * from './useCachedResources';
export * from "./useColors";
export * from "./useKeyboard";
export function useSafeAreaStyles() {
  const insets = useSafeAreaInsets();
  const androidNotch =
    (Platform.OS === "android" ? StatusBar?.currentHeight : 0) || 0;

  return {
    safeAreaTop: {
      paddingTop: insets.top,
    },
    safeAreaBottom: {
      paddingBottom: insets.bottom + androidNotch,
    },
    safeAreaLeft: {
      paddingLeft: insets.left,
    },
    safeAreaRight: {
      paddingRight: insets.right,
    },
    safeAreaHorizontal: {
      paddingLeft: insets.left,
      paddingRight: insets.right,
    },
    safeAreaVertical: {
      paddingTop: insets.top,
      paddingBottom: insets.bottom + androidNotch,
    },
    safeArea: {
      paddingTop: insets.top,
      paddingBottom: insets.bottom + androidNotch,
      paddingLeft: insets.left,
      paddingRight: insets.right,
    },
  };
}
