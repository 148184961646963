import en from "./en.json";
import ar from "./ar.json";
import es from "./es.json";
import config from "@/config";
export { useT, useAppLang, $t } from "@/hooks/useLang";

const langFiles = {
  en,
  ar,
  es,
};

export type LangTranslation = typeof en;

export const langCodes = config.languages.map((l) => l);
export const Languages = Object.entries(langFiles).reduce((acc, cur) => {
  // @ts-ignore
  acc[cur[0]] = cur[1];
  return acc;
}, {} as { [key: string]: LangTranslation });
