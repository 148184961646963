import { firebaseConfig } from "@/firebase/index.web";
import { initializeApp } from "firebase/app";
import * as fa from "firebase/analytics";
const app = initializeApp(firebaseConfig);
const gaAnalytics = fa.getAnalytics(app);

export function setUserProperty(a: any, b: any) {
  fa.setUserProperties(gaAnalytics, { [a]: b });
}

export function setUserId(a: any) {
  fa.setUserId(gaAnalytics, a);
}

export function logEvent(a: any, b: any) {
  fa.logEvent(gaAnalytics, a, b);
}

export function logPurchase(a: any) {
  fa.logEvent(gaAnalytics, "purchase", a);
}

export function logScreenView(a: any) {
  fa.logEvent(gaAnalytics, "screen_view", a);
}

export function logSignUp(a: any) {
  fa.logEvent(gaAnalytics, "sign_up", a);
}

export default function () {
  return {
    setUserProperty,
    setUserId,
    logEvent,
    logPurchase,
    logScreenView,
    logSignUp,
  };
}
