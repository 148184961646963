import { type IConfig } from ".";
import Logo from "./logos/centroid";

const config = {
  app_name: "Centroid",
  logo: (props) => <Logo width={props?.width || 300} />,
  horizontal_logo: (props) => <Logo width={props?.width || 300} />,
  languages: ["en", "ar"],
  default_language: "en",
  theme: {
    primary: "#12abe3",
    primary_pressed: "#72cddd",
  },
  features: {
    isLeverageEnabled: false,
    isNotificationsEnabled: false,
    isSignupEnabled: true,
    isLanguageEnabled: true,
    isMTLoginEnabled: true,
    isSocialLoginEnabled: true,
    isDepositEnabled: false,
    isInternalTransferEnabled: false,
    isStatmentEnabled: false,
    isSupportEnabled: false,
    isSettingsEnabled: true,
    isNewsEnabled: true,
    isEventsEnabled: true,
    isContentCardsEnabled: false,
    isDailyUpdatesEnabled: false,
    isRecurringDepositEnabled: true,
    isTermsAndConditionsEnabled: false,
    isAppTourEnabled: false,
    isUAEPassEnabled: false,
    isWithdrawEnabled: false,
    isStaticIcons: true,
    isTradingViewChartUsed: true,
  },
  navigation: {
    Foryou: {
      isMain: true,
      isShown: true,
      icon: {
        name: "Home",
        size: 30,
      },
    },
    Watchlist: {
      isMain: false,
      isShown: true,
      icon: {
        name: "Watchlist",
        size: 30,
      },
    },
    Portfolio: {
      isMain: false,
      isShown: true,
      icon: {
        name: "Dollar",
        size: 30,
      },
    },
    Plus: {
      isMain: false,
      isShown: false,
      icon: {
        name: "Plus",
        size: 30,
      },
    },
    Profile: {
      isMain: false,
      isShown: true,
      icon: {
        name: "Profile",
        size: 30,
      },
    },
  },
} satisfies IConfig;
export default config;
