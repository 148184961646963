import { TaxStatement } from "@/Features/Profile/TaxForms/types";
import { mobileApi } from "@/Lib";
import { useQuery } from "@tanstack/react-query";

export function useDwStatement(daily: boolean, dw_id: string) {
  console.log("useDwStatement", daily, dw_id);
  const KEY = ["dw-statements", daily];
  console.log(`/user/dw-statement?dw_id=${dw_id}&daily=${daily}`);
  const query = useQuery({
    queryKey: KEY,
    // dont suspend because it will break US securities
    // the date picker will show up with previous value of "daily" before the result of the query is returned since the query depends on "daily", so daily will only traverse all components when the query is done hence opening the date picker again with the new value of "daily"
    suspense: false,
    queryFn: () =>
      mobileApi
        .get<TaxStatement[]>(`/user/dw-statement?dw_id=${dw_id}&daily=${daily}`)
        .then(({ data }) => (Array.isArray(data) ? data : [])),
  });

  return { query };
}
