import config from "@/config";
import { useStyle, View } from "@amana/ui";
import { Suspense } from "react";
import { ActivityIndicator } from "react-native";

export const Spinner = () => {
  const S = useStyle();
  const { customSignInSpinner: CustomSignInSpinner } = config.features;
  return (
    <Suspense
      fallback={
        <View
          bg="bg"
          style={[
            S.flex,
            S.justify,
            S.align,
            {
              minHeight: "30%",
            },
          ]}
        />
      }
    >
      {CustomSignInSpinner ? (
        <CustomSignInSpinner />
      ) : (
        <View style={[S.flex, S.justify, S.align]}>
          <ActivityIndicator color={config.theme.primary} size="large" />
        </View>
      )}
    </Suspense>
  );
};
