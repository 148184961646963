import { Notification } from "../types";
import { handlePushNotification } from "@/firebase/handleDataNotification";
import { makeAutoObservable } from "mobx";
import isEmpty from "lodash/isEmpty";
import { FirebaseMessagingTypes } from "@react-native-firebase/messaging";
import { MessagePayload } from "firebase/messaging";

interface WebsocketNotification {
  type: string;
  notification: {
    title?: string;
    body?: string;
    data: { messageId: string; [key: string]: string };
    channel?: string;
  };
}

export class InAppNotificationsStore {
  constructor() {
    makeAutoObservable(this, {});
  }

  notifications: Notification[] = [];

  handleFirebaseMessage = (
    notification: FirebaseMessagingTypes.RemoteMessage | MessagePayload
  ) => {
    if (!notification.data || isEmpty(notification.data)) return;

    if (!notification.data.messageId) return;

    const firebaseNotification = {
      title: notification.notification?.title,
      body: notification.notification?.body,
      data: notification.data as Record<string, string>,
      messageId: notification.data.messageId as string,
    };

    this.addNotification(firebaseNotification);
  };

  handleWebsocketNotification = (notification: WebsocketNotification) => {
    const notif = notification?.notification;
    const { messageId, ...data } = notif.data;

    if (!messageId) return;

    this.addNotification({
      title: notification.notification?.title,
      body: notification.notification?.body,
      data,
      messageId,
    });
  };

  addNotification = (notification: Notification) => {
    const index = this.notifications.findIndex(
      (n) => n.messageId === notification?.messageId
    );

    if (index >= 0) return;

    if (!isEmpty(notification?.data)) handlePushNotification(notification);

    if (notification?.title || notification?.body) {
      this.notifications.push(notification);
    }
  };

  removeNotification = (notification: Notification) => {
    this.notifications = this.notifications.filter(
      (n) => n.messageId !== notification.messageId
    );
  };
}

export const inAppNotificationsStore = new InAppNotificationsStore();
