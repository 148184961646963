import { ContentCard } from "./types";
import * as Braze from "@braze/web-sdk";
import { Linking } from "react-native";

export function getContentCards() {
  return new Promise<ContentCard[]>((resolve) => {
    const cachedCards = Braze.getCachedContentCards().cards;
    if (cachedCards) return resolve(cachedCards as unknown as ContentCard[]);

    return Braze.requestContentCardsRefresh(async () => {
      const cards = Braze.getCachedContentCards()
        .cards as unknown as ContentCard[];
      resolve(cards as any);
    });
  });
}

export function registerPushToken(_fcmtoken: string) {}

export function init(brazeKey: string, VERSION: string) {
  Braze.initialize(brazeKey, {
    baseUrl: "https://sdk.fra-02.braze.eu",
    enableSdkAuthentication: true,
    manageServiceWorkerExternally: true,
    serviceWorkerLocation: "/web/braze-service-worker.js",
    enableLogging: true,
  });
  Braze.automaticallyShowInAppMessages();
  Braze.requestPushPermission();
  Braze.openSession();
  setAttribute("app_version", VERSION);
}

export function logBrazeEvent(name: string, properties?: any) {
  Braze.logCustomEvent(name, properties);
}

export function changeUser(userId: string, token: string) {
  Braze.changeUser(userId, token);
}

export function setLanguage(lang: string) {
  return Braze?.getUser()?.setLanguage(lang);
}
export function setAttribute(key: string, value: any) {
  return Braze?.getUser()?.setCustomUserAttribute(key, value, true);
}

export function unsetAttribute(key: string) {
  return Braze.getUser()?.setCustomUserAttribute(key, null);
}

export function getInitialURL(storage: Storage) {
  Linking.getInitialURL().then((url) => {
    if (url) storage.set("DeepLink", url);
  });
}

export function requestContentCardsRefresh() {
  return Braze.requestContentCardsRefresh();
}

export function logContentCardImpression(card: ContentCard) {
  const b = Braze.logContentCardImpressions([card as any]);
  console.log("Braze: logContentCardImpression", b);
}

export function logContentCardClicked(card: ContentCard) {
  const b = Braze.logContentCardClick(card as any);
  console.log("Braze: logContentCardClicked", b);
}
