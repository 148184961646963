import { firebaseApp } from "./index.web";
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from "firebase/remote-config";

const remoteConfig = getRemoteConfig(firebaseApp);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = {
  defaultDepositAmount: "0",
  group: "A",
};

export const setupRemoteConfig = async () => {
  return fetchAndActivate(remoteConfig);
};

export const getConfigValue = (key: string) => {
  return getValue(remoteConfig, key).asString();
};
