import { mobileApi } from "../api";

import { appSettings } from "../storage";
import { getQueryParams, tryParseJson } from "../helpers";
import { langCodes } from "../t";
import { ILanguageCode } from "../const";
import { setLang } from "../analytics";
import config from "@/config";
import { makeAutoObservable, runInAction } from "mobx";
import { getLocales } from "react-native-localize";
import { I18nManager, Platform } from "react-native";
import reactNativeRestart from "react-native-restart";

const setDomAttr = (lang: string) => {
  const rtl = lang === "ar";
  if (typeof document === "undefined" || !document?.body) {
    return;
  }
  if (rtl) {
    document.body.style.direction = "rtl";
    document.body.setAttribute("dir", "rtl");
    document.body.setAttribute("lang", lang);
  } else {
    document.body.style.direction = "ltr";
    document.body.setAttribute("dir", "ltr");
    document.body.setAttribute("lang", lang);
  }
};

export class SettingsStore {
  classicModeStyle: "chart" | "chg" | "%chg" = "%chg";
  gridStyle: "2way" | "grid" | "1way" = "1way";
  lang: string = config.default_language;
  theme: "dark" | "light" = "dark";
  userVideos = [] as string[];
  showAppTour = false;
  showedAppTour = false;
  showMtWelcome = false;
  newuaepassuser = false;
  uaepass = false;
  datasharing = false;
  showMtTraidingNote = false;
  pendingDoc = false;
  dob: string | null = null;
  gender: string | null = null;

  constructor() {
    this.setupOptions = this.setupOptions.bind(this);
    this.loadSettingsFromLocalStorage();
    const rtl = this.lang === "ar";
    I18nManager.forceRTL(rtl);
    makeAutoObservable(this);
  }

  async setupOptions(this: SettingsStore) {
    //** load defaults from local storage
    this.loadSettingsFromLocalStorage();

    //** load from server
    return mobileApi
      .get("/user/onboard-state")
      .then((res) => {
        runInAction(() => {
          this.pendingDoc = res.data.pendingDoc ?? false;
          this.newuaepassuser = res.data.newuaepassuser ?? false;
          this.datasharing = res.data.datasharing ?? false;
          this.uaepass = res.data.uaepass ?? false;
          this.userVideos = Array.isArray(res.data.userVideos)
            ? res.data.userVideos
            : [];
          this.showedAppTour = res.data.showedAppTour ?? false;
          this.showMtWelcome = res.data.showMtWelcome ?? true;
          this.showMtTraidingNote = res.data.showMtTraidingNote ?? true;
        });
      })
      .catch(() => null);
  }

  loadSettingsFromLocalStorage = () => {
    const langs = new Set(langCodes);
    const themes = new Set(["dark", "light"]);
    const grids = new Set(["1way", "grid", "2way"]);

    //** LOAD SHOW APP TOUR
    this.showedAppTour = appSettings.getBoolean("__showedAppTour") ?? false;

    //** LOAD SETTINGS EXCEPT LANG AND THEME
    const state = tryParseJson(appSettings.getString("__settings") || "") || {};
    Object.assign(this, state);

    //** LOAD DEFAULT GRID STYLE based on OS
    const md = typeof document !== "undefined" ? window.innerWidth > 700 : null;
    const gridStyle = appSettings.getString("__gridStyle") as any;
    this.gridStyle = grids.has(gridStyle || "")
      ? gridStyle
      : md
      ? "2way"
      : "1way";

    //** LOAD THEME
    const savedTheme = appSettings.getString("__theme") as any;
    this.theme = themes.has(savedTheme) ? (savedTheme as "dark") : "dark";

    //** LOAD LANGUAGE
    const savedLang = appSettings.getString("__lang") as ILanguageCode;
    let lang = "en";
    if (savedLang && langs.has(savedLang)) {
      lang = savedLang as "en";
    } else {
      const defaultLang = getLocales()[0].languageCode;
      if (defaultLang === "ar" || defaultLang === "en") {
        lang = defaultLang;
      } else {
        lang = "en";
      }
    }

    this.lang = lang;
    setLang(lang);

    //*** override settings from query params (used by amana.app to redirect to us)
    if (
      typeof document !== "undefined" &&
      typeof window !== "undefined" &&
      window.location?.href
    ) {
      const qp = getQueryParams(window.location.href);
      if (qp.lang && ["ar", "en"].indexOf(qp.lang) > -1) {
        this.lang = qp.lang as "ar";
        setLang(lang);
        setDomAttr(qp.lang);
      }
      if (qp.theme && ["dark", "light"].indexOf(qp.theme) > -1) {
        this.theme = qp.theme as "dark";
      }
    }
  };

  setGridStyle(_gridStyle: "1way" | "grid" | "2way") {
    this.gridStyle = _gridStyle;
    appSettings.set("__gridStyle", _gridStyle);
  }

  setAppTour(showAppTour: boolean) {
    this.showAppTour = showAppTour;
    if (!this.showedAppTour) {
      this.showedAppTour = true;
      appSettings.set("__showedAppTour", true);
      return mobileApi
        .patch("/user/onboard-state", { showedAppTour: true })
        .catch(console.debug);
    }
  }

  setMtWelcome(showMtWelcome: boolean) {
    this.showMtWelcome = showMtWelcome;
    return mobileApi
      .patch("/user/onboard-state", { showMtWelcome })
      .catch(console.debug);
  }

  onLanguageChange() {
    const { lang } = this;

    appSettings.set("__lang", lang);
    const saving = mobileApi
      .patch("/user/onboard-state", { lang })
      .catch(console.debug);

    setDomAttr(lang);
    setLang(lang);

    return saving;
  }

  async setLang(lang: ILanguageCode) {
    if (lang === this.lang) return;

    const rtl = lang === "ar";
    I18nManager.forceRTL(rtl);
    this.lang = lang;
    const isRTL = this.lang === "ar";

    this.onLanguageChange().finally(() => {
      if (isRTL !== I18nManager.isRTL && Platform.OS !== "web") {
        reactNativeRestart.Restart();
      }
    });

    return;
  }

  async setTheme(theme: "dark" | "light") {
    if (theme !== this.theme) {
      this.theme = theme;
      appSettings.set("__theme", theme);
      mobileApi.patch("/user/onboard-state", { theme }).catch(console.debug);
    }
  }

  setMtTraidingNote(showMtTraidingNote: boolean) {
    this.showMtTraidingNote = showMtTraidingNote;
    return mobileApi
      .patch("/user/onboard-state", { showMtTraidingNote })
      .catch(console.debug);
  }
}

export const settingsStore = new SettingsStore();

// export function debounceAsync<T, Callback extends (...args: any[]) => Promise<T>>(
//   callback: Callback,
//   wait = 1000
// ): (...args: Parameters<Callback>) => Promise<T> {
//   let promise: Promise<T> | null = null;

//   return (...args: any[]) => {
//     if (promise !== null) {
//       return promise;
//     }

//     promise = new Promise(resolve => setTimeout(resolve, wait))
//       .then(() => callback(...args))
//       .finally(() => {
//         setTimeout(() => {
//           promise = null;
//         }, wait || 0);
//       });

//     return promise;
//   };
// }
