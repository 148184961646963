import { testId } from "@/Lib";
import { navigateToRoot, navigationRef } from "@/helpers/navigation";
import {
  IconButton,
  If,
  Text,
  useAppLang,
  useMediaQuery,
  useStyle,
  View,
} from "@amana/ui";
import { ViewStyle } from "react-native";
interface HeaderProps {
  hasBack?: boolean;
  hasExit?: boolean;
  goBack?: () => void;
  exit?: () => void;
  text?: string;
  children?: React.ReactElement;
  style?: ViewStyle;
}
export function Header({
  hasBack = true,
  hasExit = true,
  goBack = () => navigationRef.goBack(),
  exit = () =>
    navigationRef.canGoBack()
      ? navigateToRoot()
      : navigationRef.navigate("MainScreen" as any),
  text,
  style,
  children,
}: HeaderProps) {
  const S = useStyle();
  const { md } = useMediaQuery();
  const lang = useAppLang();
  return (
    <View
      style={[S.row, S.justifyBetween, S.align, !md && S.mb6, style]}
      testID={testId("Header-View-1")}
    >
      <If
        condition={hasBack}
        fallback={
          <View
            style={{
              width: 48,
              height: 48,
            }}
            testID={testId("Header-View-2")}
          />
        }
      >
        <IconButton
          rtlIcon={lang === "ar"}
          name="Back"
          onPress={goBack}
          testID={testId("Header-IconButton-3")}
        />
      </If>

      <If
        condition={!!text}
        fallback={children || <View testID={testId("Header-View-4")} />}
      >
        <Text color="color" variant="Field" testID={testId("Header-Text-5")}>
          {text}
        </Text>
      </If>

      <If
        condition={hasExit}
        fallback={<View testID={testId("Header-View-6")} />}
      >
        <IconButton
          name="Union"
          onPress={exit}
          testID={testId("Header-IconButton-7")}
        />
      </If>
    </View>
  );
}
