import { makeAutoObservable } from "mobx";
import { createContext, ReactNode, useContext, useState } from "react";
import type { Country } from "./screens/lead-phase/CountryOfResidenceScreen/types";
class SignupStore {
  private type: string = "email";
  private token: string = "";
  private email: string = "";
  private password: string = "";
  private fname: string = "";
  private lname: string = "";
  private country: Country = {
    country_id: 229,
    iso_alpha2_code: "AE",
    iso_alpha3_code: "ARE",
    name: "United Arab Emirates",
    show_on_nationality: 1,
    show_on_residency: 1,
  };
  private nationality: Country = {
    country_id: 229,
    iso_alpha2_code: "AE",
    iso_alpha3_code: "ARE",
    name: "United Arab Emirates",
    show_on_nationality: 1,
    show_on_residency: 1,
  };
  private phoneCode: string = "";
  private phoneNumber: string = "";
  private mt_preference: string = "";
  verificationId: string = "";
  constructor() {
    makeAutoObservable(this);
  }
  get MT_Preference() {
    return this.mt_preference;
  }
  set MT_Preference(mt_preference: string) {
    this.mt_preference = mt_preference;
  }
  get Email() {
    return this.email;
  }
  set Email(email: string) {
    this.email = email;
  }
  get Password() {
    return this.password;
  }
  set Password(password: string) {
    this.password = password;
  }
  get Fname() {
    return this.fname;
  }
  set Fname(fname: string) {
    this.fname = fname;
  }
  get Lname() {
    return this.lname;
  }
  set Lname(lname: string) {
    this.lname = lname;
  }
  get Country() {
    return this.country;
  }
  set Country(country: Country) {
    this.country = country;
  }
  get Nationality() {
    return this.nationality;
  }
  set Nationality(nationality: Country) {
    this.nationality = nationality;
  }
  get PhoneCode() {
    return this.phoneCode;
  }
  set PhoneCode(phoneCode: string) {
    this.phoneCode = phoneCode;
  }
  get PhoneNumber() {
    return this.phoneNumber;
  }
  set PhoneNumber(phoneNumber: string) {
    this.phoneNumber = phoneNumber;
  }
  get Token() {
    return this.token;
  }
  set Token(token: string) {
    this.token = token;
  }
  get Type() {
    return this.type;
  }
  set Type(type: string) {
    this.type = type;
  }
}
const context = createContext<SignupStore | undefined>(undefined);
export const useSignupStore = () => useContext(context)!;
export const SignupStoreProvider = ({ children }: { children: ReactNode }) => {
  const [store] = useState(() => new SignupStore());
  return <context.Provider value={store}>{children}</context.Provider>;
};
