const useTimezoneLocation = () => {
  const userTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const EU_TIMEZONES = [
    "Asia/Famagusta",
    "Asia/Nicosia",
    "Atlantic/Azores",
    "Atlantic/Madeira",
    "Africa/Ceuta",
    "Atlantic/Canary",
  ];

  return {
    timeZone: userTZ,
    isEurope: EU_TIMEZONES.includes(userTZ) || userTZ.includes("Europe"),
  };
};

export default useTimezoneLocation;
