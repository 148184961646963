import { ReactElement, useEffect, useState } from "react";
import { Keyboard, Platform, View } from "react-native";

const useKeyboardHeight = () => {
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);
  useEffect(() => {
    const n = Keyboard.addListener(
      Platform.OS === "android" ? "keyboardDidShow" : "keyboardWillShow",
      (frames: any) => setKeyboardHeight(frames.endCoordinates.height)
    );
    const n2 = Keyboard.addListener(
      Platform.OS === "android" ? "keyboardDidHide" : "keyboardWillHide",
      () => setKeyboardHeight(0)
    );

    // cleanup function
    return () => {
      n.remove();
      n2.remove();
    };
  }, []);

  return keyboardHeight;
};

export const HideIfKeyboard = ({
  children,
  keyboardVerticalOffset = 50,
}: {
  children: ReactElement;
  keyboardVerticalOffset?: number;
}) => {
  return useIsKeyboardShown() ? (
    <View style={{ height: keyboardVerticalOffset || 0 }} />
  ) : (
    children
  );
};

export default useKeyboardHeight;

import type { EmitterSubscription } from "react-native";

export function useIsKeyboardShown() {
  const [isKeyboardShown, setIsKeyboardShown] = useState(false);

  useEffect(() => {
    const handleKeyboardShow = () => setIsKeyboardShown(true);
    const handleKeyboardHide = () => setIsKeyboardShown(false);

    let subscriptions: EmitterSubscription[];

    if (Platform.OS === "ios") {
      subscriptions = [
        Keyboard.addListener("keyboardWillShow", handleKeyboardShow),
        Keyboard.addListener("keyboardWillHide", handleKeyboardHide),
      ];
    } else {
      subscriptions = [
        Keyboard.addListener("keyboardDidShow", handleKeyboardShow),
        Keyboard.addListener("keyboardDidHide", handleKeyboardHide),
      ];
    }

    return () => {
      subscriptions.forEach((s) => s.remove());
    };
  }, []);

  return isKeyboardShown;
}
