import { MT } from "../types";
import { makeAutoObservable, observable } from "mobx";

export type WatchListRowItemProps = {
  onPress?: any;
  index: number | undefined;
  item: string;
  drag?: () => void;
  listIdx?: any;
  itemRefs: React.MutableRefObject<Map<any, any>>;
};

export class WatchListStore {
  topGainers = {} as Dictionary<MT.SymbolState>;
  selectedSymbols = [] as string[];

  // list top 50 topGainers by Perc where lev = 0
  _Gainers = [] as MT.SymbolState[];
  get Gainers() {
    return this._Gainers;
  }

  set Gainers(data: MT.SymbolState[]) {
    this._Gainers = getGainers(data);
  }

  _Losers = [] as MT.SymbolState[];
  get Losers() {
    return this._Losers;
  }

  set Losers(data: MT.SymbolState[]) {
    this._Losers = getLosers(data);
  }

  get SelectedSymbols() {
    return this.selectedSymbols;
  }

  clear() {}

  constructor() {
    makeAutoObservable(
      this,
      {
        topGainers: observable.ref,
        _Gainers: observable.ref,
        _Losers: observable.ref,
        selectedSymbols: observable.ref,
      },
      { deep: false }
    );
  }

  addSelectedSymbols(key: string) {
    if (!this.selectedSymbols.includes(key)) {
      this.selectedSymbols = [...this.selectedSymbols, key];
    } else {
      this.selectedSymbols = this.selectedSymbols.filter((el) => el !== key);
    }
  }
}

export const watchlistStore = new WatchListStore();

function getGainers(data: MT.SymbolState[]) {
  const ret = [];
  if (data.length) {
    let i = 0;
    while (ret.length < 50 && i < data.length) {
      const item = data[i++];
      if (item && !item.lev) {
        ret.push(item);
      }
    }
    return ret;
  }
  return [];
}

function getLosers(data: MT.SymbolState[]) {
  const ret = [];
  if (data.length) {
    let i = data.length;
    while (ret.length < 50 && i > 0) {
      const item = data[--i];
      if (item && !item.lev) {
        ret.push(item);
      }
    }
    return ret;
  }
  return [];
}
