import { Image, useStyle, View } from "@amana/ui";

export const GFCLogo = () => {
  const S = useStyle();
  return (
    <View style={[S.wFull, S.align, S.justify]}>
      <Image
        source={require("./gfc-logo.png")}
        style={{ width: 200, height: 200 }}
      />
    </View>
  );
};
