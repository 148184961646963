import 'react-native-gesture-handler';
import App from './src';
import {AppRegistry, I18nManager} from 'react-native';
import { appSettings } from './src/Lib/storage';

global.setImmediate = fn => setTimeout(fn, 0);
I18nManager.allowRTL(true);

const startup = require('./src/setup.web');
startup.startBraze();
startup.startSentry();
startup.startAppsFlyer();
startup.getConversionData();
startup.startAppsFlyer();


// read all query params that start with ref_ and save them in local storage
// Use URLSearchParams to extract the value of 'sidc'
try {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  const sidc = params.get('sidc')
  if (sidc) appSettings.set('sidc', sidc);

  const introducer = params.get('introducer')
  if (introducer) appSettings.set('introducer', introducer);

  const suid = params.get('suid')
  if (suid) appSettings.set('suid', suid);
} catch (error) {
  console.error("Error reading query params", error) 
}

AppRegistry.registerComponent('AmanaApp', () => App);
AppRegistry.runApplication('AmanaApp', {
  rootTag: document.getElementById('root'),
});
