import { ExchangeRate } from "./ExchangeRate";
import { ordersStore } from "../OrderStore";
import { symbolStore } from "../SymbolStore";
import { divide, multiply, subtract } from "@/Lib/Math";
import { priceEngine } from "@/Lib/PriceEngine";
import { api } from "@/Lib/api";
import DeviceEventEmitter from "@/Lib/DeviceEmitter";
import { makeAutoObservable, runInAction } from "mobx";
import type { Tapi } from "@/Lib/websocket";

export class Order {
  id = "";
  symbol: string;
  type: 0 | 1 | 2 | 3 | 4 | 5;
  size: number;
  currSize: number;
  UIopenPrice!: number | string;
  openPrice!: number;
  createdAt!: number;
  updatedAt!: number;

  profitRate = 1;
  sl = 0;
  tp = 0;
  limit = 0;
  initialOpenPrice = 0;
  ExchangeRate: ExchangeRate;

  constructor(props: Tapi.Order) {
    this.id = String(props.Order);
    this.symbol = props.Symbol;
    this.type = Number(props.Type) as 0;
    this.size = props.VolumeInitialExt
      ? divide(Number(props.VolumeInitialExt), 100000000)
      : divide(Number(props.VolumeInitial), 10000);
    this.currSize = props.VolumeCurrentExt
      ? divide(Number(props.VolumeCurrentExt), 100000000)
      : divide(Number(props.VolumeCurrent), 10000);
    this.openPrice = Number(props.PriceOrder);
    this.UIopenPrice = props.PriceOrder;
    this.initialOpenPrice = Number(props.PriceOrder);
    this.sl = Number(props.PriceSL);
    this.tp = Number(props.PriceTP);
    this.createdAt = Number(props.TimeSetup);
    this.updatedAt = Number(props.TimeDone);
    this.ExchangeRate = new ExchangeRate(this.Symbol, "Margin");
    priceEngine.subscribe(props.Symbol);
    makeAutoObservable(this);
  }

  get marginCurrencyRate() {
    return this.ExchangeRate.fromCurrencyToAccount;
  }

  get Type() {
    const TYPES = [
      "BUY",
      "SELL",
      "BUY LIMIT",
      "SELL LIMIT",
      "BUY STOP",
      "SELL STOP",
    ];
    return TYPES[this.type];
  }

  get StopsLevel() {
    return (
      Number(this.Units * Number(this.Symbol.data.Point)) *
      Number(this.Symbol.data.StopsLevel)
    );
  }

  get tradeType() {
    return this.isBuy
      ? // -- buy operation
        this.openPrice > this.Price
        ? "BUY STOP"
        : "BUY LIMIT"
      : // --- sell operation
      this.openPrice < this.Price
      ? "SELL STOP"
      : "SELL LIMIT";
  }

  get FillDistance() {
    return subtract(this.Price, this.openPrice);
  }

  get FillDistanceNum() {
    return Math.abs(((this.Price - this.openPrice) / this.Price) * 100);
  }

  get FillDistancePerc() {
    return this.FillDistanceNum.toFixed(2);
  }

  async closeRequest() {
    api
      .delete(`/api/orders/${this.id}`)
      .then(() => {
        runInAction(() => {
          ordersStore.removePosition(this.id);
        });
      })
      .catch((e) => {
        DeviceEventEmitter.emit("navigate", [
          "OrderFailed",
          {
            msg: String(e.response?.data?.message || "Error occured!"),
          },
        ]);
      });
  }

  async updateRequest() {
    if (this.openPrice === this.initialOpenPrice) {
      ordersStore.confirmOrder = null;
      return;
    }

    console.log("call api");
    return api
      .put(`/api/orders/${this.id}`, {
        price: this.openPrice,
      })
      .then((r) => {
        if (r.data.ok !== "Request fulfilled") {
          return Promise.reject(r.data);
        }
        runInAction(() => {
          this.initialOpenPrice = this.openPrice;
        });
      })
      .catch((e) => {
        console.log("error", e);
        runInAction(() => {
          this.openPrice = this.initialOpenPrice;
        });
        DeviceEventEmitter.emit("navigate", [
          "OrderFailed",
          {
            msg: e.ok || String(e.response?.data?.message || "Error occured!"),
          },
        ]);
      });
  }

  get isBuy() {
    return this.type % 2 === 0;
  }

  get Exposure() {
    const mode = Number(this.Symbol.data.CalcMode);
    if (mode === 0) {
      return this.Units * this.marginCurrencyRate;
    }
    return this.Units * this.openPrice * this.marginCurrencyRate;
  }

  get Value() {
    const mode = Number(this.Symbol.data.CalcMode);
    if (mode === 0) {
      return this.Units * this.Price;
    }
    return this.Units * this.Price * this.marginCurrencyRate;
  }

  get RequiredMargin() {
    // const marginRate = Number(this.isBuy ? this.Symbol.data.MarginInitialBuy : this.Symbol.data.MarginInitialSell) || 1;
    return Number((this.Exposure / this.Leverage).toFixed(2));
  }

  get Leverage() {
    return this.Symbol.getLeverage(this.isBuy);
  }

  get lots() {
    return this.size || this.currSize;
  }

  get Profit() {
    return this.FillDistance * this.profitRate;
  }

  get TickColor() {
    return this.Symbol.marketOpened
      ? this.Symbol.askDir
        ? "var(--green)"
        : "var(--red)"
      : "var(--secondary_75)";
  }

  get Price() {
    const sym = this.Symbol;
    if (!sym || !sym.tick?.ask || !sym.tick?.bid) {
      return 0;
    }
    return this.Symbol.isStock
      ? sym.tick?.ltp
      : this.isBuy
      ? sym.tick?.ask
      : sym.tick?.bid;
  }

  get Symbol() {
    return symbolStore.getSymbolSync(this.symbol)!;
  }

  get Units() {
    const u = multiply(this.currSize, Number(this.Symbol.data.ContractSize));
    return Number(this.Symbol.roundUnits(u));
  }

  // get EquityPerc() {
  //   const price = this.RequiredMargin;
  //   const eq = ((price / Number(accountStore.Equity || 0)) * 100);
  //   return eq ? eq.toFixed(2) : 0;
  // }
}
