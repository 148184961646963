import { brazeKey, VERSION, DIST } from "./Env";
import { init } from "./Lib/analytics/braze";
import * as Sentry from "@sentry/react";

export function SentryTag(tag: string, value: string) {
  Sentry.setTag(tag, value);
}

export function startSentry() {
  if (__DEV__) return;
  Sentry.init({
    release: `${VERSION}`,
    dist: `${DIST}`,
    dsn: "https://de08990711924d77ace04a69da95841f@o1228533.ingest.sentry.io/6516882",
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

export function startBraze() {
  init(brazeKey, VERSION);
}

export function startAppsFlyer() {
  console.debug("Appsflyer is not implemented on web");
}

export const getConversionData = startAppsFlyer;

export function useAppReview() {
  console.debug("useAppReview NOT IMPLEMENTED YET ON WEB");

  return null;
}
