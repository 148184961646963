import { mt_type } from "./helpers";

export const REASONS = [
  ["CLIENT", "Order placed by a client manually through the client terminal."],
  ["EXPERT", "Order placed by a client with using an Expert Advisor."],
  ["DEALER", "An order placed by a client from a Web terminal."],
  ["SL", "Order placed as a result of Stop Loss activation."],
  ["TP", "Order placed as a result of Take Profit activation."],
  ["SO", "Order placed when the client reached the Stop-Out level."],
  ["ROLLOVER", "Order placed when reopening a position for charging swaps."],
  ["EXTERNAL_CLIENT", "Position opened from an external trading system."],
  ["VMARGIN", "Position reopened after charging the variation margin."],
  [
    "GATEWAY",
    "Position opened by a MetaTrader 5 gateway connected to the platform.",
  ],
  [
    "SIGNAL",
    "Position opened as a result of copying a trading signal according to the subscription in the client terminal.",
  ],
  [
    "SETTLEMENT",
    "Position opened as a result of operations associated with a futures/option delivery date. It is currently not used.",
  ],
  [
    "TRANSFER",
    "Position opened as a result of transferring a position with a calculated price to a new symbol with the same underlying asset.",
  ],
  [
    "SYNC",
    "Position opened while synchronizing a trading account state with an external system.",
  ],
  [
    "EXTERNAL_SERVICE",
    "Position opened in the external trading system for service purposes (e.g. to correct a trading state).",
  ],
  [
    "MIGRATION",
    "Position opened as a result of import of clients trading operations from the MetaTrader 4 server.",
  ],
  [
    "MOBILE",
    "Position opened via the MetaTrader 5 mobile terminal for Android or iPhone.",
  ],
  ["WEB", "Position opened via the web terminal."],
  ["SPLIT", "Position opened as a result of a symbol split."],
] as const;

export const STATES = [
  [
    "STARTED",
    "Started — the order correctness has been checked, and now the order is awaiting processing.",
  ],
  [
    "PLACED",
    "Placed — for pending orders, this state means that the order has been accepted and placed. For market orders, this state is only used when forwarding operations to an external system. In this case, this state means that the order has been forwarded and is awaiting execution in the external system.",
  ],
  ["CANCELED", "Canceled — the order has been canceled by the client."],
  [
    "PARTIAL",
    "Partially filled — the order has been partially filled, so the market operation has been executed for part of the initially requested volume.",
  ],
  ["FILLED", "Filled — the order has been filled in full."],
  ["REJECTED", "Rejected — the order has been rejected by the broker."],
  ["EXPIRED", "Expired — the order has been canceled upon expiration."],
  [
    "REQUEST_ADD",
    "The order passed (by the gateway) to be placed. This state is used for notifying that a request for placing the order is being already processed.",
  ],
  [
    "REQUEST_MODIFY",
    "The order passed (by the gateway) to be modified. This state is used for notifying that a request for modifying the order is being already processed.",
  ],
  [
    "REQUEST_CANCEL",
    "The order passed (by the gateway) to be deleted. This state is used for notifying that a request for deleting the order is being already processed.",
  ],
] as const;

export const TYPES = [
  ["BUY", "A Buy order."],
  ["SELL", "A Sell order."],
  ["BUY_LIMIT", "A Buy Limit order."],
  ["SELL_LIMIT", "A Sell Limit order."],
  ["BUY_STOP", "A Buy Stop order."],
  ["SELL_STOP", "A Sell Stop order."],
  ["BUY_STLIMIT", "A Buy Stop Limit ."],
  ["SELL_STLIMIT", "A Sell Stop Limit order."],
  [
    "CLOSE_BY",
    "A Close By order — a simultaneous closure of two opposite positions of the sane financial instrument. This operation type is only used in the hedging mode (EnMarginMode::MARGIN_MODE_RETAIL_HEDGED).",
  ],
] as const;

export const USER_STATUSES = [
  { name: "ACTIVE", status_id: 8, title: "Approved" },
  { name: "CLOSED", status_id: 24, title: "Closed" },
  { name: "COMPLIANCEREVIEW", status_id: 20, title: "Compliance Review" },
  { name: "DEMO", status_id: 25, title: "Demo Registration" },
  { name: "DISABLED", status_id: 5, title: "Disabled" },
  { name: "DORMANT", status_id: 11, title: "Dormant Account" },
  { name: "DORMANT AGAIN", status_id: 12, title: "Archived" },
  { name: "DPIPSPENDING", status_id: 21, title: "DPIPs -registration pending" },
  { name: "FAILSCORE", status_id: 19, title: "Knowledge Test Failed" },
  { name: "FATCAUS", status_id: 14, title: "FATCA-Disabled" },
  {
    name: "FPPOSPENDING",
    status_id: 22,
    title: "FPPO’s -registration pending",
  },
  { name: "INACTIVE", status_id: 10, title: "Inactive" },
  { name: "INCOMPLETE", status_id: 23, title: "Incomplete Steps" },
  { name: "INCOMPLETEKYC", status_id: 18, title: "Incomplete KYC" },
  { name: "LEVEL-1 Approved", status_id: 26, title: "LEVEL-1 Approved" },
  { name: "NEW", status_id: 1, title: "Registered" },
  { name: "NODOCS", status_id: 13, title: "Missing Documents" },
  { name: "PENDING", status_id: 3, title: "Pending KYC" },
  { name: "Pending IDWISE", status_id: 27, title: "Pending IDWISE" },
  { name: "PENDINGKYCREVIEW", status_id: 17, title: "Pending KYC Review" },
  { name: "PEPPENDING", status_id: 15, title: "Pending Promotion" },
  { name: "REJECTED", status_id: 9, title: "Rejected" },
  {
    status_id: 28,
    title: "UAEP Incomplete Steps",
    name: "UAEP Incomplete Steps",
  },
  {
    status_id: 29,
    title: "UAEP Pending ID",
    name: "UAEP Pending ID",
  },
  {
    status_id: 30,
    title: "UAEP Pending PDF",
    name: "UAEP Pending PDF",
  },
] as const;

export type UserStatus = (typeof USER_STATUSES)[number];
export type StatusIDs = (typeof USER_STATUSES)[number]["status_id"];
export type StatusNames = (typeof USER_STATUSES)[number]["name"];
export type UserStatusesMap = {
  [K in StatusIDs]: Extract<UserStatus, { status_id: K }>;
};

export const USER_STATUSES_MAP: UserStatusesMap = USER_STATUSES.reduce(
  (c: any, i: any) => {
    c[i.status_id] = i;
    return c;
  },
  {}
);

type CardType =
  | "Discover"
  | "Visa"
  | "Mastercard"
  | "American Express"
  | "JCB"
  | "Diners Club"
  | "Maestro"
  | "Mada";

export const cardSchemes: Record<string, CardType> = {
  Amex: "American Express",
  "Diners Club": "Diners Club",
  Discover: "Discover",
  JCB: "JCB",
  Mastercard: "Mastercard",
  Visa: "Visa",
  Mada: "Mada",
  Maestro: "Maestro",
} as const;

export const MaxDayTrades = 3;

export enum LanguageCodesNames {
  "aa" = "Afar",
  "ab" = "Abkhazian",
  "af" = "Afrikaans",
  "ak" = "Akan",
  "am" = "Amharic",
  "ar" = "Arabic",
  "as" = "Assamese",
  "aw" = "Awadhi",
  "ay" = "Aymara",
  "az" = "Azerbaijani",
  "b1" = "Bhojpuri",
  "b2" = "Maithili",
  "ba" = "Bashkir",
  "be" = "Belarussian",
  "bg" = "Bulgarian",
  "bh" = "Bihari",
  "bi" = "Bislama",
  "bl" = "Balochi",
  "bn" = "Bengali",
  "bo" = "Tibetan",
  "br" = "Breton",
  "ca" = "Catalan",
  "cb" = "Cebuano",
  "ce" = "Chechen",
  "co" = "Corsican",
  "cs" = "Czech",
  "cy" = "Welsh",
  "da" = "Danish",
  "de" = "German",
  "dk" = "Dakhini",
  "dz" = "Bhutani",
  "el" = "Greek",
  "en" = "English",
  "eo" = "Esperanto",
  "es" = "Spanish",
  "et" = "Estonian",
  "eu" = "Basque",
  "fa" = "Persian",
  "fi" = "Finnish",
  "fj" = "Fiji",
  "fo" = "Faeroese",
  "fr" = "French",
  "fy" = "Frisian",
  "ga" = "Irish",
  "gd" = "ScottishGaelic",
  "gl" = "Galician",
  "gn" = "Guarani",
  "gu" = "Gujarati",
  "ha" = "Hausa",
  "hi" = "Hindi",
  "hr" = "Croatian",
  "ht" = "HaitianCreole",
  "hu" = "Hungarian",
  "hy" = "Armenian",
  "ia" = "Interlingua",
  "ie" = "Interlingue",
  "ik" = "Inupiak",
  "in" = "Indonesian",
  "is" = "Icelandic",
  "it" = "Italian",
  "iw" = "Hebrew",
  "ja" = "Japanese",
  "ji" = "Yiddish",
  "jw" = "Javanese",
  "ka" = "Georgian",
  "kb" = "Kabyle",
  "ki" = "Konkani",
  "kk" = "Kazakh",
  "kl" = "Greenlandic",
  "km" = "Khmer",
  "kn" = "Kannada",
  "ko" = "Korean",
  "ks" = "Kashmiri",
  "ku" = "Kurdish",
  "ky" = "Kirghiz",
  "la" = "Latin",
  "lb" = "Luxembourgish",
  "lm" = "Lombard",
  "ln" = "Lingala",
  "lo" = "Laothian",
  "lt" = "Lithuanian",
  "lv" = "Latvian",
  "mg" = "Malagasy",
  "mi" = "Maori",
  "mk" = "Macedonian",
  "ml" = "Malayalam",
  "mn" = "Mongolian",
  "mo" = "Moldavian",
  "mr" = "Marathi",
  "ms" = "Malay",
  "mt" = "Maltese",
  "mu" = "Makhuwa",
  "mw" = "Marwari",
  "my" = "Burmese",
  "na" = "Nauru",
  "ne" = "Nepali",
  "nl" = "Dutch",
  "no" = "Norwegian",
  "oc" = "Occitan",
  "om" = "Oromo",
  "or" = "Oriya",
  "pa" = "Punjabi",
  "pl" = "Polish",
  "ps" = "Pashto",
  "pt" = "Portuguese",
  "qu" = "Quechua",
  "ri" = "Rifian",
  "rm" = "RhaetoRomance",
  "rn" = "Kirundi",
  "ro" = "Romanian",
  "ru" = "Russian",
  "rw" = "Kinyarwanda",
  "sa" = "Sanskrit",
  "sd" = "Sindhi",
  "sg" = "Sangro",
  "sh" = "SerboCroatian",
  "si" = "Sinhalese",
  "sk" = "Slovak",
  "sl" = "Slovenian",
  "sm" = "Samoan",
  "sn" = "Shona",
  "so" = "Somali",
  "sq" = "Albanian",
  "sr" = "Serbian",
  "ss" = "Siswati",
  "st" = "Sesotho",
  "su" = "Sundanese",
  "sv" = "Swedish",
  "sw" = "Swahili",
  "ta" = "Tamil",
  "te" = "Telugu",
  "tg" = "Tajik",
  "th" = "Thai",
  "ti" = "Tigrinya",
  "tk" = "Turkmen",
  "tl" = "Tagalog",
  "tm" = "Tuareg",
  "tn" = "Setswana",
  "to" = "Tonga",
  "tr" = "Turkish",
  "ts" = "Tsonga",
  "tt" = "Tatar",
  "tw" = "Twi",
  "tz" = "Tamazight",
  "ug" = "Uyghur",
  "uk" = "Ukrainian",
  "ur" = "Urdu",
  "uz" = "Uzbek",
  "vi" = "Vietnamese",
  "vo" = "Volapuk",
  "wo" = "Wolof",
  "xh" = "Xhosa",
  "yo" = "Yoruba",
  "zh" = "Chinese",
  "zu" = "Zulu",
}

export type ILanguageCode = keyof typeof LanguageCodesNames;

export const mt_account_name = {
  [mt_type.MT4]: "MT4",
  [mt_type.MT5_HEDGED]: "MT5 (Hedged)",
  [mt_type.MT5_NETTING]: "MT5 (Netting)",
  [mt_type.MOBILE]: "amana",
};
